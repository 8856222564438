import type { InjectionKey, Ref } from 'vue'
import type { Emitter } from 'mitt'

export const CSRF_TOKEN: InjectionKey<Ref<string>> = Symbol('CSRF_TOKEN')

interface AppContext {
    navbarOpen: Ref<boolean>,
    navbarCollapsed: Ref<boolean>
}
export const APP_CONTEXT: InjectionKey<AppContext> = Symbol('App context')

export type ContentEventBus = {
    'comment:select': App.Domain.Content.Data.CommentData,
}
interface ValidationContext {
    content: App.Domain.Content.Data.ContentData
    eventBus: Emitter<ContentEventBus>
    focusMode: Ref<boolean>
    annotationMode: Ref<boolean>
}
export const VALIDATION_VIEW_CONTEXT: InjectionKey<ValidationContext> = Symbol('Content validation context')

// former keys
export const FILE_ASSET_KEY = Symbol('FILE_ASSET')
export const IMAGE_RESIZER_KEY = Symbol('IMAGE_RESIZER')
